// import validator from "validator"

import {langValidate} from "./validateTranslate"
let lang = localStorage.getItem("langFromStorage")
const translate = langValidate[lang]


export const  passwordValidation = (password)=> {
    // const isValid = validator.isStrongPassword(password, {
    //     minLength: 6
    //   })


    const isValid = password.length > 7
    
    return {
        isValid,
        message: translate.tr_passLengthValidate
    }
}

export const usernameValidation = (username) => {
      const isValid =  username.length > 2 && username.length < 18

      return {isValid, message: translate.tr_minAndMaxLength}
}

export const usernameSymbolValidation = (username) => {
    const allowed = /[A-z0-9\._\-]/gm
    const isValid = username && username.match(allowed).join("") === username

    return {isValid, message: translate.tr_symbolsValidation}
}