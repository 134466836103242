import { useContext, useRef, useState } from "react";
import VideoRoom from "../../states/VideoRoom";
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import SelectOption from "../SelectOption";
import {useStore} from "../../store";
import { useEffect } from "react";
import { ALERT_MESSAGE_TYPE_DANGER, ALERT_MESSAGE_TYPE_SUCCESS } from "../../const/Constants";
import AlertComponent from "../../utils/Alert";
import {socket} from "../../const/socket";
import SOCKET from "../../const/socketNames"
import {getDateByMonthAndHour} from "../../utils/helpers"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWindowClose} from "@fortawesome/free-solid-svg-icons";
import { axiosInstance } from "../../const/axiosInstance";
import Clock from "../Clock/Clock";
import { langContext } from "../../App";
import { langChangeRoom } from "./changeRoomTranslate";


function ChangeRoom({ room, closeModal, setIsOpen}) {
    const lang = useContext(langContext);
    let showBySelected = langChangeRoom[lang]

    const [roomName, setRoomName] = useState(room.name)
    const [date, setDate] = useState(new Date(room.start_date))
    const [hour, setHour] = useState(new Date(room.start_date).toString().substr(16, 5))
    const [requestedDate, setRequestedDate] = useState("")
    const {usersStore, videoRoomStore} = useStore();
    const [openModal, setOpenModal] = useState(false)
    const [error, setError] = useState(false)
    const [selected, setSelected] = useState([])
    const [options] = useState(function () {
        var res = [];
        Object.keys(usersStore.users).forEach((index) => {
            res = [...res, { value: usersStore.users[index].username, label: usersStore.users[index].name || usersStore.users[index].last_name ? usersStore.users[index].name + " " + usersStore.users[index].last_name : usersStore.users[index].username }]
        });
        return res;
    })
    const onChange = (selectedOptions) => setSelected(selectedOptions);

    useEffect(() => {
        setSelected(room.invited_users.map(el => ({ value: el, label: videoRoomStore.getFullNameByUserName(el) })))
    }, [])

    const countRef = useRef("")

    useEffect(() => {
        const getTime= async () => {
           const date = await axiosInstance.get("/devices/time")
           setRequestedDate(date.data.data)
        }
        getTime()
    }, [])



    const handleSubmit = async (e) => {
        e.preventDefault()
        const invited_users = selected.map(el => el.value)
    
        const newIsoDate = new Date(date.toISOString());
        newIsoDate.setHours(newIsoDate.getHours() + 4);
        const isoDate = new Date(getDateByMonthAndHour(newIsoDate.toISOString(), hour))
        isoDate.setHours(isoDate.getHours() - 4);
        const result = await VideoRoom.updateVideoRoomById({
            roomId: room._id,
            info: {
                room_name: e.target.room.value,
                count: countRef.current.value,
                start_date: isoDate.toISOString(),
                invited_users
            }
        })
        const new_invited_users = new Set([...room.invited_users, ...invited_users])

        socket.emit(SOCKET.MEETING_CREATE, Array.from(new_invited_users))
        const status = result.status ? ALERT_MESSAGE_TYPE_SUCCESS : ALERT_MESSAGE_TYPE_DANGER
        AlertComponent(status, result.message)
        closeModal()

        VideoRoom.getMyVideoMeetings()
    }

    const handleRoomNameChange = (event) => {
        setRoomName(event.target.value)
    }

    return (
        <form onSubmit={handleSubmit} className="mk-sizes mk-font" style={{ maxWidth: '400px' }}>
            <div className="mk-main-div" onClick={() => setOpenModal(false)}>
                <div className="mk-header">
                    <h2 className="mk-font-17"> {showBySelected.tr_changeData}</h2>

                    <button type="button" onClick={() => closeModal()}>
                        <img src="/shapeclose.png" />
                    </button>
                </div>

                <div className="mk-16">
                    <label className="mk-font-17" htmlFor="room">{showBySelected.tr_meetingTitle} :</label>
                    <input
                        type="text"
                        id="room"
                        name="room"
                        maxLength={30}
                        className="mk-input"
                        placeholder={showBySelected.tr_meetingTitle}
                        value={roomName}
                        onChange={(e) => handleRoomNameChange(e)}
                        required
                    />
                </div>

                <div style={{"display":"none"}}>
                    <label htmlFor="room">{showBySelected.tr_numberOfParticipants}:</label>
                    <input
                        type="number"
                        id="participant_count"
                        name="count"
                        disabled
                        ref={countRef}
                        value={selected.length}
                        className="border-2 mb-2"
                        required
                    />
                </div>

                <div className='mk-16'>
                    <p className="mk-font-17">{showBySelected.tr_participants}</p>
                    <SelectOption
                        isMulti
                        value={selected}
                        options={options}
                        onChange={onChange}
                        className="basic-multi-select mk-select-input"
                        classNamePrefix="select"
                        placeholder={showBySelected.tr_selectUsers}
                    />
                </div>

                <div className="mk-16">
                    <Calendar className="mk-calendar" value={date} onChange={setDate} minDate={new Date()} />
                    <div style={{"display" : "flex", "margin-top" : "5px"}}>
                        <label htmlFor="appt">{showBySelected.tr_selectTime}։ </label>
                        <Clock setOpenModal={setOpenModal} openModal={openModal} date={date} requestedDate={requestedDate} setHour={setHour} setError={setError} />
                    </div>
                </div>
                {error && <div style={{color: "red"}}>{showBySelected.tr_chooseTime}{`${new Date(requestedDate).getHours() < 10 ? "0" + new Date(requestedDate).getHours() : new Date(requestedDate).getHours()}:${new Date(requestedDate).getMinutes() < 10 ? "0" + new Date(requestedDate).getMinutes() : new Date(requestedDate).getMinutes()}`}</div>}
                <div className="mk-btn-pos">
                    <button className="mk-btn" disabled={error} type="submit">{showBySelected.tr_acceptTheChange}</button>
                </div>
            </div>
        </form>
    );
}

export default ChangeRoom;
