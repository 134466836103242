import { ALERT_MESSAGE_TYPE_DANGER, ALERT_MESSAGE_TYPE_SUCCESS } from "../const/Constants"

import { NotificationManager} from 'react-notifications';
import { langAlert } from "./alertTranslate";

let lang = localStorage.getItem("langFromStorage")
const translate = langAlert[lang]

const AlertComponent = (type, message) => {
        switch (type) {
            case ALERT_MESSAGE_TYPE_SUCCESS:
                NotificationManager.success(translate.tr_succeeded, message);
                break;
            case ALERT_MESSAGE_TYPE_DANGER:
                NotificationManager.error(translate.tr_error, message, 5000);
                break;
            default:
                NotificationManager.info("Please contact with Admin");
                break;
        }
}

export default AlertComponent