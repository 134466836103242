export const langNewMessage = {
    "arm": {
        tr_newMessage: "Նոր նամակ",
        tr_leaveComment: "Թողնել մեկնաբանություն",
        tr_selectUsers: "Ընտրել օգտվողներին",
        tr_send: "Ուղարկել",
        tr_groupMails: "Խմբային Նամակ",
        tr_recipient: "Ստացող օգտատերեր",
        tr_fileSizeValidation: "Ֆայլը չպետք է գերազանցի 5GB-ը",
        tr_loading: "Բեռնվում է...",
    },
    "eng": {
        tr_newMessage: "New message",
        tr_leaveComment: "Leave a comment",
        tr_selectUsers: "Select users",
        tr_send: "Send",
        tr_groupMails: "Group Mail",
        tr_recipient: "Recipient users",
        tr_fileSizeValidation: "File must not exceed 5GB",
        tr_loading: "Loading...",
    },
    "fr": {
        tr_newMessage: "Nouveau courrier/message",
        tr_leaveComment: "Laisser un commentaire",
        tr_selectUsers: "Sélectionnez les utilisateurs",
        tr_send: "Envoyer",
        tr_groupMails: "Messagerie de groupe",
        tr_recipient: "Utilisateurs destinataires",
        tr_fileSizeValidation: "Le fichier ne doit pas dépasser 5 Go",
        tr_loading: "Chargement...",
    },
    "arb": {
        tr_newMessage: "رسالة جديدة",
        tr_leaveComment: "ترك تعليق",
        tr_selectUsers: "اختيار المستخدمين",
        tr_send: "إرسال",
        tr_groupMails: "البريد الجماعي",
        tr_recipient: " سوف يستقبلون المستخدمين",
        tr_fileSizeValidation: "يجب ألا يتجاوز حجم الملف 5 جيجابايت",
        tr_loading: "تحميل...",
    }
}