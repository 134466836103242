export const langAlert = {
    "arm": {
        tr_succeeded: "Հաջողված է",
        tr_error: "Սխալմունք"
    },
    "eng": {
        tr_succeeded: "Succeeded",
        tr_error: "Error"
    },
    "fr": {
        tr_succeeded: "Réussi",
        tr_error: "Erreur"
    },
    "arb": {
        tr_succeeded: "نجحت",
        tr_error: "خطأ"
    },
}