export const langMeetingChat = {
    "arm": {
        tr_correspondence: "Հաղորդագրություն",
        tr_blockedUsers: "Արգելափակված օգտատերեր",
        tr_meetingWillStart(date){return `Հանդիպումը կսկսվի ժամը ${date} -ին`},
        tr_fileSizeValidation: "Ֆայլը չպետք է գերազանցի 5GB-ը",
        tr_loading: "Բեռնվում է...",
    },
    "eng": {
        tr_correspondence: "correspondence",
        tr_blockedUsers: "Blocked users",
        tr_meetingWillStart(date){return `The meeting will start at ${date}`},
        tr_fileSizeValidation: "File must not exceed 5GB",
        tr_loading: "Loading...",
    },
    "fr": {
        tr_correspondence: "Message",
        tr_blockedUsers: "Utilisateurs bloqués",
        tr_meetingWillStart(date){return `La réunion débutera à ${date}`},
        tr_fileSizeValidation: "Le fichier ne doit pas dépasser 5 Go",
        tr_loading: "Chargement...",
    },
    "arb": {
        tr_correspondence: "رسالة",
        tr_blockedUsers: "المستخدمين المحظورين",
        tr_meetingWillStart(date){return date + " سيبدأ اللقاء على الساعة"},
        tr_fileSizeValidation: "يجب ألا يتجاوز حجم الملف 5 جيجابايت",
        tr_loading: "تحميل...",
    }
}