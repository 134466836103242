import React, {useContext, useState} from 'react';
import DateTimePicker from "react-datetime-picker";
import {useStore} from "../../store";
import { langContext } from '../../App';
import { langDeleteByDate } from './deleteByDateTranslate';

const DeleteByDate = ({status, closeModal}) => {
    const lang = useContext(langContext);
    let showBySelected = langDeleteByDate[lang]

    const [endDate, setEndDate] = useState();
    const {adminStore } = useStore()


    const approveHandler = () => {
        if(status === "meeting") {
            adminStore.deleteMeetings(endDate)
        }else if(status === "user_logs") {
            adminStore.deleteUserLogs(endDate)
        } else if(status === "message_logs"){
            adminStore.deleteMessageLogs(endDate)
        }
        closeModal()
    }

    return (

        <div className="flex flex-col">
            <DateTimePicker onChange={setEndDate} value={endDate} locale="hy-AM" />

            <div
                className="flex justify-center items-center">
                <button
                    onClick={() => approveHandler()}
                        className="bg-blue-600 text-white m-2 p-2 w-36 items-center">{showBySelected.tr_confirm}
                </button>
                <button
                    onClick={() => closeModal()}
                    className="bg-red-400 text-white m-2 p-2 w-36">{showBySelected.tr_refuse}</button>
            </div>

        </div>
    );
};

export default DeleteByDate;