export const langChat = {
    "arm": {
        tr_deleteMessages: "Ջնջել Նամակները",
        tr_deleteMessage: "Ջնջել Նամակը",
        tr_seeMore: "Տեսնել ավելին",
        tr_delete: "Ջնջել",
        tr_correspondence: "Հաղորդագրություն",
        tr_send: "Ուղարկել",
        tr_myMails: "Իմ Նամակները",
        tr_fileSizeValidation: "Ֆայլը չպետք է գերազանցի 5GB-ը",
        tr_loading: "Բեռնվում է...",
    },
    "eng": {
        tr_deleteMessages: "Delete messages",
        tr_deleteMessage: "Delete message",
        tr_seeMore: "See more",
        tr_delete: "Delete",
        tr_correspondence: "Correspondence",
        tr_send: "Send",
        tr_myMails: "My Emails",
        tr_fileSizeValidation: "File must not exceed 5GB",
        tr_loading: "Loading...",
    },
    "fr": {
        tr_deleteMessages: "Supprimer les courriers/les messages",
        tr_deleteMessage: "Supprimer le courrier/es message",
        tr_seeMore: "Voir plus",
        tr_delete: "Supprimer",
        tr_correspondence: "Message",
        tr_send: "Envoyer",
        tr_myMails: "Mes e-mails",
        tr_fileSizeValidation: "Le fichier ne doit pas dépasser 5 Go",
        tr_loading: "Chargement...",
    },
    "arb": {
        tr_deleteMessages: "حذف الرسائل",
        tr_deleteMessage: "حذف الرسالة",
        tr_seeMore: "عرض المزيد",
        tr_delete: "حذف",
        tr_correspondence: "رسالة",
        tr_send: "إرسال",
        tr_myMails: "رسائل البريد الإلكتروني الخاصة بي",
        tr_fileSizeValidation: "يجب ألا يتجاوز حجم الملف 5 جيجابايت",
        tr_loading: "تحميل...",
    }
}