import React, {useContext, useEffect, useState} from "react"
import {ALERT_MESSAGE_TYPE_DANGER, ALERT_MESSAGE_TYPE_SUCCESS} from "../const/Constants";
import { langContext } from "../App";
import {useStore} from "../store"
import AlertComponent from "../utils/Alert";
import {passwordValidation} from "../utils/validate";
import { langChangePass } from "./changePasswordTranslate";


const ChangePassword = () => {
    const lang = useContext(langContext);
    let showBySelected = langChangePass[lang]

    const [data, setData] = useState({
        old_password: "",
        new_password: "",
        confirm_password: ""
    })
    const [errorMessage, setErrorMessage] = useState("");
    const [disabled, setDisabled] = useState(false)
    const [oldpass,setOldpass] = useState("password")
    const [viewpassword, setViewPassword] = useState("password")
    const [viewrepeat,setViewRepeat] = useState("password")
    const {userStore} = useStore()

   

    const handleChange = (e) => {
        const {name, value} = e.target
        setData({...data, [name]: value})
        setErrorMessage("")

        if (name === "confirm_password") {
            data.new_password !== value && setErrorMessage(showBySelected.tr_passDoesNotMatch)
        }

        if (name === "new_password") {
            const result = passwordValidation(value)
            !result.isValid && setErrorMessage(result.message)
        }
    } 
    
    useEffect(() => {
       let {new_password,confirm_password} = data
       if(new_password !== confirm_password) {
          setDisabled(true)
          if(new_password && confirm_password)
          setErrorMessage(showBySelected.tr_passDoesNotMatch)
       } else if (new_password.length < 8 ) {
          setDisabled(true)
          if(new_password.length){
          setErrorMessage(showBySelected.tr_passLengthValidate)
        }
       } else {
        setDisabled(false)
       }
    }, [errorMessage])

    const handleViewPassword = () =>{
        if(viewpassword === "password" ){
            setViewPassword("text")
        }else{
            setViewPassword("password")
        }

    }

    const handleViewRepeat = () =>{
        if(viewrepeat === "password" ){
            setViewRepeat("text")
        }else{
            setViewRepeat("password")
        }

    }

    const handleOldPassword = () =>{
        if(oldpass === "password" ){
            setOldpass("text")
        }else{
            setOldpass("password")
        }

    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const {old_password, new_password} = data
        if(new_password.length < 8) {
            setErrorMessage(showBySelected.tr_passLengthValidate)
            return
        }
        const isChanged = await userStore.changeCurrentPassword(old_password, new_password)
        const status = isChanged.status ? ALERT_MESSAGE_TYPE_SUCCESS : ALERT_MESSAGE_TYPE_DANGER
        setData({
            old_password: "",
            new_password: "",
            confirm_password: ""
        })
        AlertComponent(status, isChanged.message)

    }

    const {old_password, new_password, confirm_password} = data

    return (
        <form onSubmit={handleSubmit} className="mrg-div">
        <p className="user-page-p">{showBySelected.tr_oldPass}</p>
        <div className="pass-inputs-div">
            <input type={oldpass} name="old_password" id="old_password"
                   className="pass-inputs"
                   placeholder={showBySelected.tr_oldPass} value={old_password} onChange={(e) => handleChange(e)} required/>
            {oldpass ==="password" ? <img src="/Iconeye.png" onClick={handleOldPassword}/>
                    : <img src="/Iconcloseeye.png" onClick={handleOldPassword}/>}
        </div>

        <p className="user-page-p">{showBySelected.tr_newPass}</p>
        <div className="pass-inputs-div">
            <input type={viewpassword} name="new_password" id="new_password"
                   className="pass-inputs"
                   placeholder={showBySelected.tr_newPass} value={new_password} onChange={(e) => handleChange(e)} required/>
            {viewpassword==="password" ? <img src="/Iconeye.png" onClick={handleViewPassword}/>
                    : <img src="/Iconcloseeye.png" onClick={handleViewPassword}/>}
        </div>

        <p className="user-page-p">{showBySelected.tr_confirmPass}</p>
        <div className="pass-inputs-div">
            <input type={viewrepeat} name="confirm_password" id="confirm_password"
                   className="pass-inputs"
                   placeholder={showBySelected.tr_confirmPass} value={confirm_password} onChange={(e) => handleChange(e)} required/>
            {viewrepeat==="password" ? <img src="/Iconeye.png" onClick={handleViewRepeat}/>
                    : <img src="/Iconcloseeye.png" onClick={handleViewRepeat}/>}
        </div>

        {errorMessage === '' ? null :
            <span className="">{errorMessage}</span>}

        <button type="submit" className="submit-btn" disabled={errorMessage || !data.old_password || !data.new_password || !data.confirm_password ? true : false}>
            {showBySelected.tr_confirm}
        </button>

    </form>

    )

}


export default ChangePassword 