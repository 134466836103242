export const langLogin = {
    "arm" : 
    {tr_notMatch: "Գաղտնաբառը չի համընկնում",
        tr_pleaseFill: "Խնդրում եմ լրացնել դաշտերը",
        tr_passLangValid: "Գաղտնաբառր պետք է ունենա մինիմում 8 սիմվոլ",
        tr_videoConnection: "ՏԵսակոնֆերենս Կապ",
        tr_password: "Գաղտնաբառ",
        tr_passwordConfirm: "Հաստատել Գաղտնաբառը",
        tr_passwordChange: "Փոփոխել գաղտնաբառը",
        tr_name: "Անուն",
        tr_logIn: "Մուտք գործել",
        tr_help: "Օգնություն",
        tr_registr: "Գրանցվել",
        tr_confSystem: "ՏԵՍԱՁԱՅՆԱՅԻՆ ԿՈՆՖԵՐԱՆՍ ԿԱՊԻ ՀԱՄԱԿԱՐԳ",
        tr_fillThePin: "Լրացնել PIN կոդը",
        tr_pinNotExist: "Պին կոդը գոյություն չունի",
        tr_phoneMust: "Անհրաժեշտ է ճիշտ լրացնել հեռախոսահամարը",
        tr_mailMust: "Անհրաժեշտ է լրացնել էլ․ հասցեն",
        tr_mailMustBeTrue: "Անհրաժեշտ է ճիշտ լրացնել էլ․ հասցեն",
        tr_wrongCode: "Հաստատման կոդը սխալ է",
        tr_requestForReg: "Դիմել գրանցման",
        tr_fillEmail: "Լրացրե՛ք Ձեր էլ․ հասցեն",
        tr_fillPhone: "Լրացրե՛ք Ձեր հեռախոսահամարը",
        tr_mailRequired: "Էլ․ հասցե (պարտադիր դաշտ)",
        tr_phoneInt: "Հեռախոսահամար (միջազգային)",
        tr_fillVerCode: "Լրացրե՛ք հաստատման գաղտնաբառը։ Այն ուղարկվել է Ձեր էլ․ հասցեին",
        tr_confirmCode: "Հաստատման գաղտնաբառ",
        tr_confirm: "Հաստատել",
        tr_reqAccepted: "Ձեր հայտն ընդունված է։",
        tr_soonWillConnect: "Մեր մասնագետները շուտով կկապնվեն ձեզ հետ",
        tr_main: "Գլխավոր",
        tr_fillAcceptedPin: "Լրացնել ստացված Pin կոդը",

    },
    "eng": {tr_notMatch: "The password does not match",
        tr_pleaseFill: "Please fill in the fields",
        tr_passLangValid: "The password should have at least 8 characters",
        tr_videoConnection: "Video conference connection",
        tr_password: "Password",
        tr_passwordConfirm: "Confirm the password",
        tr_passwordChange: "Change the password",
        tr_name: "Name",
        tr_logIn: "Log in",
        tr_help: "Help",
        tr_registr: "Register",
        tr_confSystem: "VIDEO CONFERENCE COMMUNICATION SYSTEM",
        tr_fillThePin: "Fill in the PIN code",
        tr_pinNotExist: "The pin code does not exist",

        tr_phoneMust: "It is necessary to fill in the phone number correctly",
        tr_mailMust: "It is necessary to fill in an e-mail address.",
        tr_mailMustBeTrue: "It is necessary to fill in the correct e-mail address",
        tr_wrongCode: "The verification code is incorrect",
        tr_requestForReg: "Apply for registration",
        tr_fillEmail: "Fill in your email address",
        tr_fillPhone: "Fill in your phone number",
        tr_mailRequired: "Email address (mandatory field)",
        tr_phoneInt: "Phone number (international)",
        tr_fillVerCode: "Enter the confirmation password. It has been sent to your email. to the address",
        tr_confirmCode: "Confirmation password",
        tr_confirm: "Confirm",
        tr_reqAccepted: "Your report is accepted.",
        tr_soonWillConnect: "Our experts will contact you soon",
        tr_main: "Main",
        tr_fillAcceptedPin: "Enter the received Pin code",

    },
    "fr": {tr_notMatch: "Mot de passe invalide",
        tr_pleaseFill: "Remplissez les champs requis",
        tr_passLangValid: "Le mot de passe doit contenir au minimum 8 caractères",
        tr_videoConnection: "Connexion de vidéoconférence",
        tr_password: "Mot de passe",
        tr_passwordConfirm: "Confirmer le mot de passe",
        tr_passwordChange: "Changer le mot de passe",
        tr_name: "Nom",
        tr_logIn: "Identifiez-vous pour accéder",
        tr_help: "Aide",
        tr_registr: "Enregistrement",
        tr_confSystem: "SYSTÈME DE COMMUNICATION PAR VIDÉOCONFÉRENCE",
        tr_fillThePin: "Saisir le code PIN",
        tr_pinNotExist: "Le Code PIN n’existe pas",

        tr_phoneMust: "Il est nécessaire de renseigner correctement le numéro de téléphone",
        tr_mailMust: "Il est nécessaire de renseigner une adresse e-mail.",
        tr_mailMustBeTrue: "Il est nécessaire de renseigner la bonne adresse e-mail",
        tr_wrongCode: "Le code de vérification est incorrect",
        tr_requestForReg: "Faire une demande d'inscription",
        tr_fillEmail: "Remplissez votre adresse email",
        tr_fillPhone: "Remplissez votre numéro de téléphone",
        tr_mailRequired: "Adresse email (champ obligatoire)",
        tr_phoneInt: "Numéro de téléphone (international)",
        tr_fillVerCode: "Entrez le mot de passe de confirmation. Il a été envoyé à votre adresse e-mail. à l'adresse",
        tr_confirmCode: "Mot de passe de confirmation",
        tr_confirm: "Confirmer",
        tr_reqAccepted: "Votre rapport est accepté.",
        tr_soonWillConnect: "Nos experts vous contacteront prochainement",
        tr_main: "Principal",
        tr_fillAcceptedPin: "Entrez le code PIN reçu",

    },
    "arb": {tr_notMatch: "كلمة السرّ غير متطابقة",
        tr_pleaseFill: "يرجى ملء الحقول",
        tr_passLangValid: "كلمة السرّ يجب أن يكون مؤلّفاً من 8 رموز على الأقلّ",
        tr_videoConnection: "المكالمة الجماعية عبر الفيديو",
        tr_password: "كلمة السرّ",
        tr_passwordConfirm: "تأكيد كلمة السرّ",
        tr_passwordChange: "تغيير كلمة السرّ",
        tr_name: "الاسم",
        tr_logIn: "تسجيل الدخول",
        tr_help: "المساعدة",
        tr_registr: "التسجيل",
        tr_confSystem: "نظام اتصالات مؤتمرات الفيديو",
        tr_fillThePin: "ملء كلمة المرور",
        tr_pinNotExist: "رمز المرور غير موجودة",

        tr_phoneMust: "من الضروري ملء رقم الهاتف بشكل صحيح",
        tr_mailMust: "ومن الضروري ملء عنوان البريد الإلكتروني. العنوان",
        tr_mailMustBeTrue: "ومن الضروري ملء عنوان البريد الإلكتروني الصحيح",
        tr_wrongCode: "رمز التحقق غير صحيح",
        tr_requestForReg: "تقدم بطلب التسجيل",
        tr_fillEmail: "املأ عنوان بريدك الإلكتروني",
        tr_fillPhone: "املأ رقم هاتفك",
        tr_mailRequired: "عنوان البريد الإلكتروني (حقل إلزامي)",
        tr_phoneInt: "رقم الهاتف (الدولي)",
        tr_fillVerCode: "أدخل كلمة المرور التأكيدية. لقد تم إرساله إلى البريد الإلكتروني الخاص بك. إلى العنوان",
        tr_confirmCode: "تأكيد كلمة المرور",
        tr_confirm: "يتأكد",
        tr_reqAccepted: "تم قبول تقريرك.",
        tr_soonWillConnect: "سوف يتصل بك خبراؤنا قريبًا",
        tr_main: "رئيسي",
        tr_fillAcceptedPin: "أدخل الرقم السري المستلم",

    }
        
}