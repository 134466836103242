import { observer } from "mobx-react-lite"
import { useContext, useEffect, useRef, useState } from "react"
import { ALERT_MESSAGE_TYPE_SUCCESS, BACKEND_URL, MESSAGE_MY } from "../const/Constants"
import { SocketContext } from "../const/socket"
import avatarImage from "../assets/images/user_avatar.png"
import { useStore } from "../store"
import FileUpload from "./FileUpload"
import MessageItem from "./MessageItem"
import SOCKET from "../const/socketNames"
import DeleteMessageRoom from "./DeleteMessageRoom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightArrowLeft,faCheck, faCheckCircle, faPaperPlane, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import UserChat from "../states/UserChat";
import AlertComponent from "../utils/Alert";
import { Menu } from "@headlessui/react";
import { langContext } from "../App"
import { langPrivatChat } from "./privateChatTranslate"
import "./message.css"
import userAvatar from "../assets/images/user_avatar.png"
import Modal from "./modal/CustomModal"
import FileUploadPeer from "./FileUploadPeer.tsx"
import { Provider} from "react-redux"
import {store} from "./store/index.ts"


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const SingleChat = observer(({selected, fromP2P, setFromP2P}) => {
    const lang = useContext(langContext);
    let showBySelected = langPrivatChat[lang]

    const { userStore, chatStore, usersStore } = useStore();
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [bigFile, setBigFile] = useState(false)
    const [offset, setOffset] = useState(0)
    const [allUsers, setAllUsers] = useState({})
    const [size, setSize] = useState(0)
    const [isOpenP2P,setIsOpenP2P] = useState(false)
    const ref = useRef(null)
    const socket = useContext(SocketContext)


    function scrollDown() {
        document.getElementById('messages-small').scrollTop = document.getElementById('messages-small').scrollHeight
    }


    async function moreData() {
        if (chatStore.username !== "ALL") {
            await chatStore.getMessages({ roomId: chatStore.getRoomId, offset: offset + 10 });
            setOffset(offset => offset + 10)
        } else if (chatStore.user === 'ALL') {
            setOffset(offset => offset + 10)
            await chatStore.getAllMessages(offset, 10)

        }
    }

    const closeP2PModal = () => {
        setFromP2P(false)
        setIsOpenP2P(false)
    }

    const sendMessage = () => {
        if (!message) {
            return
        }

        socket.emit(SOCKET.CHAT_PRIVATE_GET_MESSAGE, {
            message: message.replace(/\r?\n/g,"\n").trim(),
            room_id: chatStore.getRoomId,
            role: userStore.role,
            sender_username: userStore.username,
            receiver_username: chatStore.username,
            size
        })
	chatStore.getMessages({ roomId: chatStore.getRoomId,offset:0, limit:chatStore.messageHistory.length + 1 })
        setMessage('')

    }

    useEffect(() => {
        setBigFile(false)
    }, [selected])

    const chatCloseHandleClick = () => {
        socket.emit(SOCKET.CHAT_CLOSE, { username: userStore.username, guestUsername: chatStore.username })
        chatStore.clearUsername()
        chatStore.clearMessages()
    }

    const chatHandleClick = (guestUsername) => {
        setOffset(10)
        chatStore.clearUsername()
        chatStore.clearMessages()
        if (guestUsername === 'Admin') {
            socket.emit(SOCKET.CHAT_CREATE, { username: userStore.username, guestUsername: "Admin" })
        } else {
            socket.emit(SOCKET.CHAT_CREATE, { username: userStore.username, guestUsername: guestUsername })
        }
        chatStore.setUsername(guestUsername)
    }

    useEffect(() => {
        socket.on(SOCKET.CHAT_ROOM_INFO, (room) => {
            chatStore.setRoomId(room.id)
            socket.emit(SOCKET.CHAT_PRIVATE_JOIN_ROOM, { roomId: room.id })
            chatStore.getMessages({ roomId: chatStore.getRoomId, offset: 0 });
        })
        socket.on(SOCKET.GET_MY_MESSAGE_MYSELF, (message) => {
            chatStore.addMessage(message.message, MESSAGE_MY, new Date().toLocaleDateString('en', {
                day: '2-digit', month: '2-digit', year: '2-digit', hour: 'numeric', hour12: false, minute: 'numeric'
            }), null, message._id)
            setTimeout(function () {
                scrollDown()
            }, 0)
        })
        
        return () => {
            socket.off(SOCKET.GET_MY_MESSAGE_MYSELF)
            socket.off(SOCKET.CHAT_ROOM_INFO)
            socket.off(SOCKET.CHAT_PRIVATE_JOIN_ROOM)
        }
    }, [])


    useEffect(() => {
        if(fromP2P){
            openP2PChat(userStore.username, chatStore.user); setIsOpenP2P(true)
            chatStore.getMessages({ roomId: chatStore.getRoomId, offset: 0 })
        }
    }, [fromP2P])


    useEffect(() => {
        socket.on(SOCKET.CHAT_PRIVATE_SEND_MESSAGE, function (data) {
            if (chatStore.username === data.username) chatStore.addMessage(
                data.message, data.type, new Date().toLocaleDateString('en', {
                    day: '2-digit', month: '2-digit', year: '2-digit', hour: 'numeric', hour12: false, minute: 'numeric'
                }), null, data.id)
            if (data.role === "Admin") {
                chatStore.setAdminChat(true)
                socket.emit(SOCKET.CHAT_VIEWED_ROOMS, { name: userStore.username, caller: data.username })
            } else {
                socket.emit(SOCKET.CHAT_VIEWED_ROOMS, { name: userStore.username, caller: data.username })
            }

            setTimeout(function () {
                scrollDown()
            }, 0)
        })
        socket.on(SOCKET.UPDATE_MESSAGES, () => {
            if(chatStore.user === 'ALL'){
                chatStore.getAllMessages(0, chatStore.messageHistory.length)
            }else {
                chatStore.getMessages({ roomId: chatStore.getRoomId,offset:0, limit:chatStore.messageHistory.length })
            }
        })
        return () => {
            socket.off(SOCKET.CHAT_PRIVATE_SEND_MESSAGE)
            socket.off(SOCKET.CHAT_PRIVATE_GET_MESSAGE)
        }
    }, [socket])

    useEffect(() => {
        socket.on(SOCKET.CHAT_REFRESH_ROOMS, (data) => {
            if (chatStore.user === data.guest_username) {
                chatStore.setIsRead()
            }
            if(chatStore.user === 'ALL'){
                chatStore.getAllMessages(0, chatStore.messageHistory.length)
            }else {
                chatStore.getMessages({ roomId: chatStore.getRoomId,offset:0, limit:chatStore.messageHistory.length })
            }
        })
        return () => {
            socket.off(SOCKET.CHAT_REFRESH_ROOMS)
        }
    }, [])

    useEffect(() => {
        if (chatStore.username === 'ALL') {
            chatStore.getAllMessages(0, 10)
            setOffset(10)
            usersStore.users.forEach(el => setAllUsers(prev => ({ ...prev, [el.username]: el.name || el.last_name ? `${el.name} ${el.last_name}` : el.username })))
            usersStore.unviewedChats = []
        }
        if (chatStore.username === 'Admin') {
            chatStore.getAdminMessages()
        }
        setTimeout(function () {
            scrollDown()
        }, 100)
    }, [chatStore.username])

    const deleteChatRoom = async () => {
        if (window.confirm(showBySelected.tr_deleteMessages)) {
            const result = await UserChat.deleteMessageRoomById()
            socket.emit(SOCKET.DELETE_ALL_MESSAGES,  chatStore.user)
            if (result.status) {
                AlertComponent(ALERT_MESSAGE_TYPE_SUCCESS, result.message)
                chatCloseHandleClick()
            }
        }
    }

    const openP2PChat = (me, to) => {
        socket.emit("CLICKED_P2P_CHAT", {user: me, connectWith: to })
    }

    const deleteChatMessage = async (message) => {
        if (window.confirm(showBySelected.tr_deleteMessage)) {
            chatStore.deleteMessageById(message).then(res => {
                socket.emit(SOCKET.DELETE_MESSAGE,  chatStore.user)
                if(chatStore.user === 'ALL'){
                    chatStore.getAllMessages(0, chatStore.messageHistory.length)
                }else {
                    chatStore.getMessages({ roomId: chatStore.getRoomId,offset:0, limit:chatStore.messageHistory.length })
                }
            }
               )
        }
    }





    return (
    <div ref={ref} className="ms-div">
        <div>
            <div className="chat-header">
                <div className="user-img-name-div">
                    {(chatStore.user !== 'ALL') && <img
                            src={usersStore.users.find(el => el.username === chatStore.user)?.image ? `${BACKEND_URL}${usersStore.users.find(el => el.username === chatStore.user).image}` : avatarImage}
                            className="chat-ser-img" style={{"borderRadius":"50%", "marginRight": "0"}}/>}
                    <span className="" style={{"marginLeft" : "16px"}}>
                            {(usersStore.users.find(el => el.username === chatStore.user)?.name || 
                            usersStore.users.find(el => el.username === chatStore.user)?.last_name || 
                            usersStore.users.find(el => el.username === chatStore.user)?.father_name) ? 
                            usersStore.users.find(el => el.username === chatStore.user)?.name + ' ' + usersStore.users.find(el => el.username === chatStore.user)?.last_name + ' ' + usersStore.users.find(el => el.username === chatStore.user)?.father_name : chatStore.user === 'ALL' ? showBySelected.tr_myMails : chatStore.user}
                    </span>
                </div>
                <div className="chat-icons">
                    {(chatStore.user !== 'ALL') && <img src="/deleteIcon.png" style={{"margin-right": "20px"}} onClick={() => deleteChatRoom()}/>}
                    <img src="/shapeclose.png" onClick={() => chatCloseHandleClick()} />
                </div>
            </div>
        </div>
        <div id="messages-small" className={chatStore.user === 'ALL' ? "msg-all" : "msg-window"}>
            <div className="chat-nots">
                {chatStore.messages.length >= 10 && chatStore.messages.length >= offset ? <div>
                    <button onClick={() => moreData()}>{showBySelected.tr_seeMore}</button>
                </div> : ""}
            </div>
            {chatStore.messages.map((message, index) => {
                if (message.type === MESSAGE_MY) {
                    return (<div className="my-msg-div" key={index} >
                        <div>
                            <div>
                                {(chatStore.user === 'ALL') && (<div className="fontstyle-ms"
                                        onClick={() => chatHandleClick(message.receiver_username)}>
                                        {allUsers[message.receiver_username]}
                                    </div>)}
                                <div>
                                    <Menu as="div" >
                                        <div >
                                            <Menu.Button>
                                                <span className="my-msg" style={{wordBreak: "break-all"}}>
                                                    <MessageItem message={message.message} />
                                                </span>
                                            </Menu.Button>
                                        </div>
                                        <Menu.Items>
                                            <div>
                                                <Menu.Item>
                                                    {({ active }) => 
                                                        (<div onClick={() => deleteChatMessage(message)}
                                                                className={classNames(active ? 'bg-gray-100 text-gray-900 msg-delete-btn' : 'text-gray-700 msg-delete-btn', 'block px-4 py-2 text-sm')}>
                                                            <img src="/deleteIcon.png"/>
                                                            {showBySelected.tr_delete}
                                                        </div>)}
                                                </Menu.Item>
                                            </div>
                                        </Menu.Items>
                                    </Menu>
                                </div>
                                <span className="time-viewed">
                                     {message.created_at} 
                                     {chatStore.user !== 'ALL' &&( message.is_viewed ? (
                                        <img src="/receiptviewed.png" />) : (
                                        <img src="/receiptnotviewed.png" />))}
                                </span>
                            </div>
                        </div> 
                    </div>)
                } else {
                    return (<div key={index} >
                        <div style={{"width" :"318px"}}>
                            <div >
                                {(chatStore.user === 'ALL') && (<div className="fontstyle-ms m-l-17"
                                    onClick={() => chatHandleClick(message.username)}>
                                    {allUsers[message.username]}
                                </div>)}
                                <div >
                                    <Menu as="div">
                                        <div>
                                            <Menu.Button>
                                                <span className="msg-me" style={{wordBreak: "break-all"}}>
                                                    <MessageItem message={message.message} />
                                                </span>
                                            </Menu.Button>
                                        </div>
                                        <Menu.Items>
                                            <div>
                                                <Menu.Item>
                                                    {({ active }) => (<div onClick={() => deleteChatMessage(message)}
                                                            className={classNames(active ? 'bg-gray-100 text-gray-900 msg-delete-btn' : 'text-gray-700 msg-delete-btn', 'block px-4 py-2 text-sm')}
                                                            style={{"right":"250px", "margin-left":"16px"}}>
                                                            <img src="/deleteIcon.png"/>{showBySelected.tr_delete}
                                                    </div>)}
                                                </Menu.Item>
                                            </div>
                                        </Menu.Items>
                                    </Menu>
                                    <span className="me-time-viewd">
                                        {message.created_at}
                                    </span>
                                </div>
                            </div>
                        </div> 
                    </div>)
                }
                })
            }
        </div>
        {chatStore.user !== 'ALL' && (<div>
            <div className="send-div">
                <div className="send-msg">
                        <FileUpload setSize={setSize} setField={setMessage} setLoading={setLoading} className="upload-img"
                            uploadFile={chatStore.uploadMessages} setBigFile={setBigFile} idName="file-input-private">
                                <img src="/Iconfileupload.png" style={{"cursor":"pointer"}}/>
                        </FileUpload>
                        <div style={{cursor: "pointer", marginLeft:"10px"}} onClick={() => {openP2PChat(userStore.username, chatStore.user); setIsOpenP2P(true)}}>
                            <FontAwesomeIcon icon={faArrowRightArrowLeft} style={{color: "#2C507B"}}/>
                        </div>
                    <input type="text"
                        value={message}
                        onKeyDown={(event) => event.key === 'Enter' && (event.ctrlKey || event.metaKey || event.shiftKey) ? (() =>{event.preventDefault(); sendMessage()})() : ''}
                        onChange={(event) =>{message.length < 4000 ? setMessage(event.target.value) : ""}}
                        placeholder={bigFile? showBySelected.tr_fileSizeValidation : loading ? showBySelected.tr_loading : showBySelected.tr_correspondence}
                        className="text-send" />
                </div>
                <div className="send-btn-privat">
                    <button
                        disabled={loading}
                        type="button"
                        onClick={sendMessage}>
                            <img src="/sendmsg.png"/>
                    </button>
                </div>
            </div>
        </div>)}
        <Modal
            isClose={() => closeP2PModal()}
            isOpen={isOpenP2P}>
                      <Provider store={store}>
                         <FileUploadPeer setIsOpenP2P={setIsOpenP2P} setFromP2P={setFromP2P}/>
                      </Provider>
        </Modal>
    </div>)
})

export default SingleChat
