export const langRegister ={
    "arm":{
        tr_passwordDontMatch: "Գաղտնաբառը չի համընկնում",
        tr_pleaseFill: "Խնդրում եմ լրացնել դաշտերը",
        tr_passLengthValid: "Գաղտնաբառր պետք է ունենա մինիմում 8 սիմվոլ",
        tr_conferanceConnection: "Տեսակոնֆերենս կապ",
        tr_name: "Անուն",
        tr_password: "Գաղտնաբառ",
        tr_repeatPass: "Կրկնել Գաղտնաբառը",
        tr_fillThePin: "Լրացնել PIN կոդը",
        tr_register: "Գրանցվել",
        tr_help: "Օգնություն",
        tr_logIn: "Մուտք գործել",
        tr_confSystem: "ՏԵՍԱՁԱՅՆԱՅԻՆ ԿՈՆՖԵՐԱՆՍ ԿԱՊԻ ՀԱՄԱԿԱՐԳ", 
        tr_minAndMaxLength: "Օգտանունը պետք է լինի մինիմում 3 սիմվոլ և մաքսիմում 18 սիմվոլ",
        tr_allowedSymbols: "Օգտանունը կարող է պարունակել լատինատառ սիմվոլներ, թվեր և \" ․ \" _ \" - \"",
        tr_passDoesntMatch: "Գաղտնաբառը չի համընկնում",
    },
    "eng":{
        tr_passwordDontMatch: "The password does not match",
        tr_pleaseFill: "Please fill in the fields",
        tr_passLengthValid: "The password should have at least 8 characters",
        tr_conferanceConnection: "Video conference connection",
        tr_name: "Name",
        tr_password: "Password",
        tr_repeatPass: "Repeat the password",
        tr_fillThePin: "Fill in the PIN code",
        tr_register: "Register",
        tr_help: "Help",
        tr_logIn: "Log in",
        tr_confSystem: "VIDEO CONFERENCE COMMUNICATION SYSTEM",
        tr_minAndMaxLength: "Username must be at least 3 characters and a maximum of 18 characters",
        tr_allowedSymbols: "Username can contain Latin characters, numbers and \" . \" _ \" - \"",
        tr_passDoesntMatch: "Password does not match",
    },
    "fr":{
        tr_passwordDontMatch: "Mot de passe invalide",
        tr_pleaseFill: "Remplissez les champs requis",
        tr_passLengthValid: "Le mot de passe doit contenir au minimum 8 caractères",
        tr_conferanceConnection: "Connexion de vidéoconférence",
        tr_name: "Nom",
        tr_password: "Mot de passe",
        tr_repeatPass: "Répétez le mot de passe",
        tr_fillThePin: "Saisir le code PIN",
        tr_register: "Enregistrement",
        tr_help: "Aide",
        tr_logIn: "Identifiez-vous pour accéder",
        tr_confSystem: "SYSTÈME DE COMMUNICATION PAR VIDÉOCONFÉRENCE",
        tr_minAndMaxLength: "Le nom d'utilisateur doit comporter un minimum de 3 caractères et un maximum de 18 caractères",
        tr_allowedSymbols: "Le nom d'utilisateur peut contenir des caractères latins, des chiffres et \" . \" _ \" - \"",
        tr_passDoesntMatch: "Le mot de passe ne correspond pas",
    },
    "arb":{
        tr_passwordDontMatch: "كلمة السرّ غير متطابقة",
        tr_pleaseFill: "يرجى ملء الحقول",
        tr_passLengthValid: "كلمة السرّ يجب أن يكون مؤلّفاً من 8 رموز على الأقلّ",
        tr_conferanceConnection: "المكالمة الجماعية عبر الفيديو",
        tr_name: "الاسم",
        tr_password: "كلمة السرّ",
        tr_repeatPass: "إعادة كلمة السرّ",
        tr_fillThePin: "ملء كلمة المرور",
        tr_register: "تسجيل",
        tr_help: "المساعدة",
        tr_logIn: "تسجيل الدخول",
        tr_confSystem: "نظام اتصالات مؤتمرات الفيديو",
        tr_minAndMaxLength: "يجب أن لا يقل اسم المستخدم عن 3 أحرف ولا يزيد عن 18 حرفًا",
        tr_allowedSymbols: "يمكن أن يحتوي اسم المستخدم على أحرف لاتينية وأرقام و\" . \" _ \" - \"",
        tr_passDoesntMatch: "كلمة المرور غير متطابقة",
    }
}