
export const langValidate = {
    "arm": {
        tr_passLengthValidate: "Գաղտնաբառր պետք է ունենա մինիմում 8 սիմվոլ",
        tr_minAndMaxLength: "Օգտանունը պետք է լինի մինիմում 3 սիմվոլ և մաքսիմում 18 սիմվոլ",
        tr_symbolsValidation: "Օգտանունը պարունակում է չթույլատրված սիմվոլներ"     
    },
    "eng": {   
        tr_passLengthValidate: "Password must have at least 8 characters",
        tr_minAndMaxLength: "Username must be at least 3 characters and a maximum of 18 characters",
        tr_symbolsValidation: "Username contains illegal characters"      
    },
    "fr": {  
        tr_passLengthValidate: "Le mot de passe doit comporter au moins 8 caractères",
        tr_minAndMaxLength: "Le nom d'utilisateur doit comporter un minimum de 3 caractères et un maximum de 18 caractères",
        tr_symbolsValidation: "Le nom d'utilisateur contient des caractères illégaux" 
    },
    "arb": {  
        tr_passLengthValidate: "يجب أن تحتوي كلمة المرور على 8 أحرف على الأقل",
        tr_minAndMaxLength: "يجب أن لا يقل اسم المستخدم عن 3 أحرف ولا يزيد عن 18 حرفًا",
        tr_symbolsValidation: "يحتوي اسم المستخدم على أحرف غير قانونية"     
    },
}