export const langMeetingList = {
    "arm": {
        tr_meetingdate(a,b,c) {
            return `Հանդիպումը կսկսվի ${a} ${b} ամսաթվի ժամը ${c} –ին`
        },
        tr_alreadyInCall: "Դուք արդեն զանգի մեջ եք",
        tr_name: "Անվանում",
        tr_participant: "Մասնակից",
        tr_time: "Ժամ",
        tr_status: "Վիճակ",
        tr_toParticipate: "Մասնակցել",
        tr_change: "Փոփոխել",
        tr_cancel: "Չեղարկել",
        tr_delete: "Ջնջել",
        tr_canclReason: "Չեղարկելու պատճառը",
        tr_newConf: "Նոր Կոնֆերանս",
        tr_established: "Կայացած",
        tr_started: "Սկսված",
        tr_cancelled: "Չեղարկված",
        tr_late: "Ուշացած",
        tr_overdue: "Սպասվող",
        tr_meetingdateUpcoming(a,b,c) {
            return `Հանդիպումը կսկսվի ${a} ${b} ամսաթվի ժամը ${c} –ին, որին կարող եք միանալ արավելագույնը 10 րոպե շուտ `
        }
    },
    "eng": {
        tr_meetingdate(a,b,c) {
            return `The meeting will begin on the date ${a} ${b} at ${c} `
        },
        tr_alreadyInCall: "You are being called",
        tr_name: "Name",
        tr_participant: "Participant",
        tr_time: "Time",
        tr_status: "Status",
        tr_toParticipate: "To participate",
        tr_change: "Change",
        tr_cancel: "Cancel",
        tr_delete: "Delete",
        tr_canclReason: "Basis for cancellation",
        tr_newConf: "New conference",
        tr_established: "Established",
        tr_started: "Started",
        tr_cancelled: "Cancelled",
        tr_late: "Late",
        tr_overdue: "Overdue",
        tr_meetingdateUpcoming(a,b,c) {
            return `The meeting will begin on the date ${a} ${b} at ${c}, you can join up to 10 minutes earlier`
        }
    },
    "fr": {
        tr_meetingdate(a,b,c) {
            return `La réunion débutera le ${a} ${b} à  ${c} `
        },
        tr_alreadyInCall: "Vous avez un appel",
        tr_name: "Nom",
        tr_participant: "Participant",
        tr_time: "Heure",
        tr_status: "Statut",
        tr_toParticipate: "Participer",
        tr_change: "Modifier",
        tr_cancel: "Annuler",
        tr_delete: "Supprimer",
        tr_canclReason: "Raison d’annulation",
        tr_newConf: "Nouvelle Conférence",
        tr_established: "Établi",
        tr_started: "Commencé",
        tr_cancelled: "Annulé",
        tr_late: "En retard",
        tr_overdue: "En attente",
        tr_meetingdateUpcoming(a,b,c) {
            return `La réunion débutera le ${a} ${b} à  ${c}, vous pourrez la rejoindre jusqu'à 10 minutes plus tôt`
        }
    },
    "arb": {
        tr_meetingdate(a,b,c) {
            return a + " " + b + " "+ "من تاريخ " + c + " " + "يبدأ اللقاء في الساعة  " 
        },
        tr_alreadyInCall: "أنت في حالة اتّصال",
        tr_name: "التسمية",
        tr_participant: "المشارك",
        tr_time: "الساعة",
        tr_status: "الحالة",
        tr_toParticipate: "مشاركة",
        tr_change: "تعديل",
        tr_cancel: "إلغاء",
        tr_delete: "حذف",
        tr_canclReason: "سبب الإلغاء",
        tr_newConf: " مؤتمر جديد",
        tr_established: "اختر اللغة",
        tr_started: " بدأت",
        tr_cancelled: "ألغيت",
        tr_late: "متأخر",
        tr_overdue: "قيد الانتظار",
        tr_meetingdateUpcoming(a,b,c) {
            return `${a + " " + b + " "+ "من تاريخ " + c + " " + "يبدأ اللقاء في الساعة  " }والتي يمكنك الانضمام إليها قبل 10 دقائق,`
        }
    }
}