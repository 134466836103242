export const uploadPeer = {
    "arm": {
        tr_sucsessfullySent: "Ֆայլը հաջողությամբ ուղարկվեց",
        tr_error: "Սխալմունք, փորձեք կրկին",
        tr_pleaseWait: "Խնդրում ենք սպասել ...",
        tr_isntOnline: "Օգտատերը հասանելի չէ",
        tr_connectedWith(user){
            return `Դուք կապի մեջ եք ${user}֊ի հետ`
        },
        tr_sending: "Ուղարկվում է",
        tr_send: "Ուղարկել",
        tr_chooseFile: "Ընրել ֆայլ"
    },
    "eng": {
        tr_sucsessfullySent: "File sent successfully",
        tr_error: "Error, try again",
        tr_pleaseWait: "Please wait...",
        tr_isntOnline: "The user is not available",
        tr_connectedWith(user){
            return `You are in contact with ${user}`
        },
        tr_sending: "Sending",
        tr_send: "Send",
        tr_chooseFile: "Choose a file"
    },
    "fr": {
        tr_sucsessfullySent: "Fichier envoyé avec succès",
        tr_error: "Erreur, réessayez",
        tr_pleaseWait: "S'il vous plaît, attendez...",
        tr_isntOnline: "L'utilisateur n'est pas disponible",
        tr_connectedWith(user){
            return `Vous êtes en contact avec ${user}`
        },
        tr_sending: "Envoi",
        tr_send: "Envoyer",
        tr_chooseFile: "Choisissez un fichier"
    },
    "arb": {
        tr_sucsessfullySent: "تم إرسال الملف بنجاح",
        tr_error: "خطأ، حاول مرة أخرى",
        tr_pleaseWait: "انتظر من فضلك...",
        tr_isntOnline: "المستخدم غير متوفر",
        tr_connectedWith(user){
            return "أنت على اتصال مع"  + " " + user
        },
        tr_sending: "إرسال",
        tr_send: "يرسل",
        tr_chooseFile: "اختر ملفًا"
    }
}