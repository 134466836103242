import { useCallback, useRef, useState} from "react";
import {useStore} from "../../store";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { SocketContext } from "../../const/socket";
import SelectOption from "../SelectOption";
import FileUpload from "../FileUpload";
import { useContext } from "react";
import UserChat from "../../states/UserChat";
import SOCKET from "../../const/socketNames"
import {MESSAGE_MY} from "../../const/Constants";
import {observer} from "mobx-react-lite";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import { langContext } from "../../App";
import { langNewMessage } from "./newMessageTranslate";
import "./groupChat.css"


const NewMessage = observer(({setIsOpen}) => {
    const lang = useContext(langContext);
    let showBySelected = langNewMessage[lang]

    const {usersStore, userStore} = useStore()
    const [selected, setSelected] = useState([])
    const [message, setMessage] = useState(null)
    const [loading, setLoading] = useState(false)
    const [bigFile, setBigFile] = useState(false)
    const [filename, setFileName] = useState('')
    const errorMessage = useRef();

    const [options] = useState(function () {
        var res = [];
        Object.keys(usersStore.users).forEach((index) => {
            res = [...res,{value:usersStore.users[index].username,label:usersStore.users[index].name || usersStore.users[index].last_name ? usersStore.users[index].name + " " + usersStore.users[index].last_name : usersStore.users[index].username }]
        });
        return res;
    })
     
    const socket = useContext(SocketContext)

    const onChange = useCallback( (selectedOptions) => setSelected(selectedOptions), []);
    const chatCloseHandleClick = () => {
        setIsOpen(false)
    }

    const sendMessage = () => {
        selected.map(el => {
            UserChat.addMessage(message, MESSAGE_MY,
                new Date().toLocaleDateString('en',
                    {day: '2-digit', month: '2-digit', year: '2-digit', hour: 'numeric', hour12: false, minute: 'numeric'}),
                el.value
                )
        })
        socket.emit(SOCKET.SEND_MESSAGE_ALL, {usernames: selected.map(el => el.value), message, sender_username: userStore.username, filename})
        chatCloseHandleClick()
    }

    return (
        <div className='group-chat-main'>
            <div>
                <div className="group-chat-header">
                    <label htmlFor="message" className="fontstyle-group" style={{"font-size":"17px"}}>
                        {showBySelected.tr_groupMails}
                    </label>
                    <button type="button" onClick={() => chatCloseHandleClick()}>
                        <img src="/shapeclose.png"/>
                    </button>
                </div>
            </div>
            <p className="fontstyle-group mr-16">{showBySelected. tr_recipient}</p>
            <div className='group-members-select'>
                <SelectOption
                    isMulti
                    value={selected}
                    options={options}
                    onChange={onChange}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder={showBySelected.tr_selectUsers}
                />
            </div>
            <div className='text-input-section'>
                <div>
                    <FileUpload setField={setFileName} uploadFile={UserChat.uploadMessages} setLoading={setLoading} purpose={true} setBigFile={setBigFile} >
                        <img src="/Iconfileupload.png" className="upload-file-icon"/>
                    </FileUpload>
                </div>
                <textarea rows="6" cols='80'
                        value={message ?? filename ?? ''} onChange={(event)=>setMessage(event.target.value)}
                        className="textarea-input fontstyle-group"
                        placeholder={bigFile? showBySelected.tr_fileSizeValidation : loading ? showBySelected.tr_loading : showBySelected.tr_leaveComment}/>
            </div>
            <div>
                <button className='send-btn' onClick={sendMessage} disabled={!selected.length && loading || !message}>
                    {showBySelected.tr_send}
                    <img src="/Iconsendarrow.png" style={{"margin-left":"5px"}}/>
                </button>
            </div>
            <div>
                <span ref={errorMessage} className="text-xs text-red-400"></span>
            </div>
        </div>
    );
})

export default NewMessage;